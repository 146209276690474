<template>
  <div class="articleLR-two" :style="articleContainStyl" ref="articleContainerLrTwo">
    <div class="more-box-top" :style="linkTopStyl" v-if="buttonContent.isShowButton">
      <a href="javascript:;" class="link-more" @click="checkMore">
        {{buttonContent.buttonName || '查看更多'}}
      </a>
    </div>
    <div class="article-inner" :style="articleInnerStyl">
      <div   
        v-for="(item, index) in articleDataList"
        :key="index"
        @click="skipLinks(item)"
        class="article-box" 
        :style="articleBoxStyl" 
        @mouseover="overArticleItem(item)"
      >
            <div class="img" :style="imageStyl" v-if="publishContent.includeItemsImageOrVideo">
          <img :src="item[showImgUrl]" alt="" v-if="item[showImgUrl]"/>
          <img src="../../asset/images/default-img.jpg" alt="" v-if="!item[showImgUrl]"/>
          <div class="video-overly" v-if="item[isFileType] == 2">
            <img src="../../asset/images/video-btn.png"
            @click="clickSwiperItem(item)" alt="">
          </div>
        </div>
        <div class="article">
          <div
            class="main-title"
            :style="mainTitle"
            v-if="publishContent.includeItemsTitle"
          >
            {{showContentType == '2'? item.articleTitle: item.titleName}}
          </div>
          <div
            class="description"
            :style="descriptionTitle"
            v-if="publishContent.includeItemsDes"
          >
            {{showContentType == '1'?item.titleDes : item.articleAbstract}}
          </div>
          <p :style="publishStyl" v-if="publishContent.showContentType == 2" class="publish-num">
            <span class="publish-time" v-if="publishContent.showPublishFlag">{{ item.publishTime |formatTime }}</span>
            <span class="obser-num" v-if="publishContent.showReadFlag"><img src="../../asset/images/obser-num-img.png" style="width: 14px;height: 14px"> {{item.readNum}}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="more-box-bottom" :style="linkBottomStyl" v-if="buttonContent.isShowButton">
      <a href="javascript:;" class="link-more-bottom"> 
        {{buttonContent.buttonName || '查看更多'}} 
      </a>
    </div>
  </div>
</template>

<script>
import {commonClickFn, checkMoreClickFn} from './commonModuleFn'
export default {
  name: "articleLR",
  props: {
    receiveProps: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    articleContainStyl() {
      let {blockMargeTop, blockMargeBottom, blockBGClolor} = this.receiveProps.tempData.specialStyle
      return {
        "--all-margin-top": `${blockMargeTop}px`,
        "--all-margin-bottom": `${blockMargeBottom}px`,
        "--all-background-color": blockBGClolor,
        "--all-box-width": this.isBanner? '1200px': '600px'
      };
    },
    linkTopStyl() {
      let { linkBtnAlign, linkBtnLocation } = this.receiveProps.tempData.specialStyle;
      return {
        "--link-text-align": linkBtnAlign,
        "--link-top-display": linkBtnLocation == "top"?"block": 'none',
      };
    },
    linkBottomStyl() {
      let { linkBtnAlign, linkBtnLocation } = this.receiveProps.tempData.specialStyle;
      return {
        "--link-text-align": linkBtnAlign,
        "--link-bottom-display": linkBtnLocation=='top'? 'none': "block",
      };
    },
    mainTitle() {
      let { titleAlign, titleBold, titleColor, titleSize, oneGroupImageLeft, oneGroupBorder} = this.receiveProps.tempData.specialStyle;
      return {
        "--main-font-color": titleColor,
        "--main-font-size": `${titleSize || 18}px`,
        "--main-font-weight": titleBold? '700': "",
        "--main-text-align": titleAlign,
        "--main-margin-top":this.publishContent.includeItemsImageOrVideo? Number(oneGroupImageLeft)?"16px": "8px" : Number(oneGroupImageLeft)&& oneGroupBorder? "16px": '',
        padding: !this.publishContent.includeItemsImageOrVideo&&Number(oneGroupImageLeft)&&oneGroupBorder?'0px 16px 0':'',
        paddingRight: '16px'
      };
    },
    descriptionTitle() {
      let { descSize, descColor, descBold, descAlign, oneGroupImageLeft, oneGroupBorder } = this.receiveProps.tempData.specialStyle;
      return {
        "--desp-font-color": descColor,
        "--desp-font-size": `${descSize || 14}px`,
        "--desp-font-weight": descBold? '700': '',
        "--desp-text-align": descAlign,
         padding: !this.publishContent.includeItemsImageOrVideo&&Number(oneGroupImageLeft)&&oneGroupBorder?'0 16px':'',
        paddingRight: '16px'
      };
    },
    publishStyl(){
      let {  oneGroupImageLeft, oneGroupBorder } = this.receiveProps.tempData.specialStyle;
      return {
        padding: !this.publishContent.includeItemsImageOrVideo&&Number(oneGroupImageLeft)&&oneGroupBorder?'0 16px 16px':''
      }
    },
    imageStyl() {
    let {imgBorder, imgRadius, imgShadow, imgBackgroundColor} = this.receiveProps.tempData.specialStyle;
      return {
        "--image-border": imgBorder? "1px solid #D6DEEA": '',
        "--image-border-radius": imgRadius? "4px": '',
        "--image-box-shadow": imgShadow? "0px 0px 8px 0px rgba(0, 0, 0, 0.1)": '',
        "--image-background-color": imgBackgroundColor,
        "--image-width": "210px",
        "--image-height": "158px",
      };
    },
    articleBoxStyl() {
      // 判断上下图文   还是左图右文
      let { oneGroupBorder, oneGroupBGColor, oneGroupImageLeft, oneGroupBorderShadow, oneGroupBorderRadius } = this.receiveProps.tempData.specialStyle;
      return {
        "--article-box-border": oneGroupBorder? "1px solid #d6deea" : '',
        "--article-box-background-color": oneGroupBGColor,
        "--article-box-padding": !Number(oneGroupImageLeft)? "16px": '', // 判断 普通状态下'16px' 当贴边的时候为''
        "--article-box-shadow": oneGroupBorderShadow?"0px 0px 16px 0px rgba(0, 0, 0, 0.1)": '', 
        "--article-box-border-radius": oneGroupBorderRadius? "4px": '', // 判断是否是圆角
        "--article-box-hover": this.showPointer ? "pointer" : "",
        "--article-box-width": this.isBanner? Number(oneGroupImageLeft)?'564px':'calc(564px - 32px)':  Number(oneGroupImageLeft)?'552px': 'calc(552px - 32px)'
      };
    },
    articleInnerStyl(){
      return {
        '--grid-template-column': this.isBanner? '1fr 1fr': '1fr'
      }
    }
  },
  data() {
    return {
      showPointer: false,
      articleDataList: [],
      showContentType: '1',
      publishContent: this.receiveProps.tempData.specialData,
      buttonContent: this.receiveProps.tempData.buttonData,
      isBanner:true,
      showImgUrl: '',
      isFileType: ''

    };
  },
  filters: {
    formatTime(val) {
      return (
        val.split(" ")[0].split("-").join("/") 
      );
    },
  },
  mounted() {
    this.getData()
    let classArr = this.$refs.articleContainerLrTwo.parentNode.parentNode.classList
    for(var i=0;i<classArr.length;i++){ //获取父节点  el-col-24  el-col-12
    
        if(classArr[i]=='el-col-12'){
          this.isBanner = false
        } 
      }
  },
  methods: {
    skipLinks(item) {
      commonClickFn(item, this.publishContent, this)
    },
    checkMore() {
      checkMoreClickFn(this.buttonContent, this.publishContent, this)
    },
    overArticleItem(item){
      // 直接调取有手指  自由设置看是否有链接
      let {showContentType} = this.receiveProps.tempData.specialData
      let {selectType} = item
      if(showContentType != 2){ 
      if(selectType){
        this.showPointer = true
        return 
      }
         this.showPointer = false
      } else {
        this.showPointer = true
      }
    },
    getData(){ 
      // 规则获取文章列表
      let {articleClassID, showContentType, showTotal, commendsRule} = this.receiveProps.tempData.specialData
       this.$api.news.getArticleInfo({ classId: articleClassID,sort: commendsRule}).then(res=>{
         this.showContentType = showContentType
         if(showContentType == '2'){
           this.articleDataList = showTotal?res.data.slice(0,showTotal):res.data
           this.showImgUrl = 'articleCover'
           this.isFileType = 'articleType'
           return 
         }
         this.articleDataList = showTotal?this.receiveProps.tempData.specialEntryList.slice(0,showTotal):this.receiveProps.tempData.specialEntryList
         this.showImgUrl = 'entryImg'
         this.isFileType = 'fileType'
       })
    }
  },
};
</script>

<style lang="less" scoped>
.articleLR-two {
  width: var(--all-box-width);
  margin: 0 auto;
  padding: 24px;
  margin-top: var(--all-margin-top);
  margin-bottom: var(--all-margin-bottom);
  background-color: var(--all-background-color);
  box-sizing: border-box;
  .link-more, .link-more-bottom{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .more-box-top {
    margin:0 24px 24px;
    text-align: var(--link-text-align);
    display: var(--link-top-display);
  }
  .more-box-bottom {
    display: var(--link-bottom-display);
    padding: 24px 24px 25px;
    text-align: var(--link-text-align);
  }
  .article-inner {
    display: grid;
    grid-template-columns: var(--grid-template-column);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    .article-box {
      display: flex;
      width: var(--article-box-width);
      border: var(--article-box-border);
      background-color: var(--article-box-background-color);
      padding: var(--article-box-padding);
      box-shadow: var(--article-box-shadow);
      .img {
        position: relative;
        width: var(--image-width);
        height: var(--image-height);
        margin-right: 16px;
        border: var(--image-border);
        border-radius: var(--image-border-radius);
        box-shadow: var(--image-box-shadow);
        background-color: var(--image-background-color);
        img {
          width: 100%;
          height: 100%;
           border-radius: var(--image-border-radius);
        }
        .video-overly{
          width: 48px;
          height: 48px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .article-box:hover{
      cursor: var(--article-box-hover);
    }
    
  }
  // 公用样式
  .article {
        flex: 1;
        .main-title {
          color: var(--main-font-color);
          font-size: var(--main-font-size);
          font-weight: var(--main-font-weight);
          text-align: var(--main-text-align);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-bottom: 8px;
          margin-top: var(--main-margin-top);
        }
        .description {
          color: var(--desp-font-color);
          font-size: var(--desp-font-size);
          font-weight: var(--desp-font-weight);
          text-align: var(--desp-text-align);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          // margin-bottom: 8px;
        }
        .publish-num{
          display: flex;
          align-items: center;
        }
        .publish-time {
          margin-top: 8px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-right: 24px;
        }
        .obser-num {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          display: inline-flex;
          align-items: center;
          img{
            margin-right: 8px;
          }
        }
      }
}
</style>